var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{ref:"mapRef",style:(_vm.page && _vm.page == 'locationDialog'
        ? 'width: 100%; height: 500px; margin: 0 auto; border-radius: 20px'
        : 'width: 100%; height: 700px; margin: 0 auto; border-radius: 20px'),attrs:{"center":_vm.center,"zoom":7,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},[_c('gmap-marker',{attrs:{"position":_vm.position,"clickable":false,"draggable":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }