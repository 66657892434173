<template>
  <div>
    <pageHeader />
    <div id="bookings">
      <v-container fluid>
        <v-row>
          <v-col>
            <div class="head">
              <h2 class="main-title mb-3">نتيجة المواعيد</h2>
              <p class="d-flex align-center">
                <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
                <span class="line"></span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="calendar-contaienr">
        <v-row>
          <v-col sm="12" md="3" class="mt-4 text-center">
            <v-select
              v-model="selectedBranch"
              label="الفروع"
              :items="branches"
              item-text="name"
              item-value="id"
              class="curve-input"
              solo
              @change="getBookingsList()"
            >
            </v-select>
            <v-date-picker
              v-if="dates && dates.length > 0"
              :show-current="false"
              :selected-items-text="`لديك ${reservations.length} طلبات`"
              color="#a53860"
              v-model="dates"
              multiple
              readonly
            ></v-date-picker>

            <!-- <v-calendar :attributes="attrs" /> -->
          </v-col>
          <v-col sm="12" md="9">
            <v-simple-table
              class="orders"
              v-if="reservations && reservations.length > 0"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center user-main-color">اسم العميل</th>
                    <th class="text-center user-main-color">رقم الجوال</th>
                    <th class="text-center user-main-color">الجلسات</th>
                    <th class="text-center user-main-color">الفرع</th>
                    <th class="text-center user-main-color">تاريخ الطلب</th>
                    <th class="text-center user-main-color">وقت الطلب</th>
                    <th class="text-center user-main-color">مكان الخدمة</th>
                    <th class="text-center user-main-color">الفاتورة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in reservations"
                    :key="index"
                    class="text-center"
                  >
                    <td>{{ item.client_name }}</td>
                    <td style="direction: ltr">{{ item.client_mobile }}</td>
                    <td>
                      <span
                        v-for="(session, index) in item.sessions"
                        :key="index"
                      >
                        {{ session }}
                        <span v-if="index < item.sessions.length - 1"> - </span>
                      </span>
                    </td>
                    <td>{{ item.branch }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.time }}</td>
                    <td>
                      <v-btn
                        v-if="item.locations == 'home'"
                        @click="openClientDialog(index)"
                        icon
                      >
                        <v-icon color="#a53860" v-if="item.locations == 'home'"
                          >mdi-home</v-icon
                        >
                      </v-btn>

                      <span v-else>بالصالون</span>

                      <clientLocation
                        :dialog="dialogs[index]"
                        v-if="dialogs[index]"
                        :lat="item.lat"
                        :lng="item.lng"
                        :formatted_address="item.formatted_address"
                        @closeDialog="closeClientDialog(index)"
                      />
                    </td>
                    <td>
                      <a
                        :href="item.pdf_url"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-if="item.pdf_url"
                      >
                        <v-icon color="#a53860">mdi-clipboard-list</v-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p
              class="text-center"
              v-if="
                reservations &&
                reservations.length == 0 &&
                loaded &&
                plan.can_show == true
              "
            >
              لا يوجد حجوزات
            </p>

            <p
              class="subscribe-msg mr-6 mt-4"
              v-if="plan && plan.can_show == false"
            >
              <v-icon large color="#a53860">mdi-alert-circle-outline</v-icon>
              {{ plan.message }}
              <router-link to="/pricingList" style="color: #a53860"
                >انقر هنا</router-link
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";

import clientLocation from "@/components/salon/clientLocation.vue";

import { mapGetters } from "vuex";

export default {
  name: "bookings",
  components: {
    pageHeader,

    clientLocation,
    // Calendar,
    // DatePicker,
  },
  data() {
    return {
      dates: [],

      dialogs: [],

      loaded: false,
      reservations: [],
      plan: {},

      branches: [],
      selectedBranch: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    openClientDialog(index) {
      this.$set(this.dialogs, index, true);
    },
    closeClientDialog(index) {
      this.$set(this.dialogs, index, false);
    },
    getBranches() {
      this.$http
        .get(this.base_url + "/salon/getSalonBranches", {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.branches = response.data.data;
          this.selectedBranch = this.branches[0].id;

          this.getBookingsList();
        });
    },
    getBookingsList() {
      this.loaded = false;
      this.dates = [];
      this.$http
        .get(
          this.base_url +
            "/salon/reservations/myAppointments/" +
            this.selectedBranch,
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loaded = true;
          this.reservations = response.data.data.reservations;
          this.plan = response.data.data.plan;
          let msg = this.plan.message;

          msg = msg.replace("انقر هنا", "");
          this.plan.message = msg;
          this.dates = response.data.data.dates;

          for (let i = 0; i < this.reservations.length; i++) {
            this.dialogs.push(false);
          }
        });
    },
  },
  mounted() {
    this.getBranches();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/_variables.scss";

#bookings {
  margin: 50px 0;
  .head {
    margin: 0 80px;
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }

  .calendar-contaienr {
    padding: 12px 80px !important;
  }
  .orders {
    th.text-center {
      border-bottom: 1px solid $user-main-color;
      font-size: 1rem !important;
    }

    td {
      font-size: 1rem !important;
      color: #4a4949 !important;
      padding-top: 16px !important;
      padding-bottom: 16px;

      a {
        text-decoration: unset !important;
      }
    }
  }

  .subscribe-msg {
    color: $second-color !important;
  }
}

@media (max-width: 600px) {
  #bookings {
    margin: 50px 0;
    .head {
      margin: 0 12px;
    }

    .calendar-contaienr {
      padding: 12px !important;
    }
  }
}
</style>
