<template>
  <div>
    <GmapMap
      :center="center"
      :zoom="7"
      ref="mapRef"
      :style="
        page && page == 'locationDialog'
          ? 'width: 100%; height: 500px; margin: 0 auto; border-radius: 20px'
          : 'width: 100%; height: 700px; margin: 0 auto; border-radius: 20px'
      "
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
    >
      <gmap-marker
        :position="position"
        :clickable="false"
        :draggable="false"
      ></gmap-marker>
    </GmapMap>
  </div>
</template>

<script>
import * as VueGoogleMaps from "vue2-google-maps";
export default {
  props: ["lat", "lng", "page"],
  data() {
    return {
      center: { lat: 24.7136, lng: 46.6753 },
      position: { lat: 24.7136, lng: 46.6753 },
    };
  },

  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  mounted() {
    this.position.lat = parseFloat(this.lat);
    this.position.lng = parseFloat(this.lng);
    this.center = this.position;
  },
};
</script>

<style></style>
