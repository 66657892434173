<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="50%"
      style="box-shadow: unset !important"
    >
      <v-card id="subscribe-dialog">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="left-col d-none d-sm-block pa-0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <Map :lat="lat" :lng="lng" page="locationDialog" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6" class="right-col pa-0">
              <v-card-text class="px-4 px-sm-8">
                <h2 class="">
                  <strong> عنوان العميل</strong>
                </h2>
                <hr class="main-hr" />
                <div class="mb-8">
                  <p>{{ formatted_address }}</p>
                </div>

                <div class="close-action text-center mt-4">
                  <v-btn class="main-btn login-btn" @click="closeDialog">
                    اغلاق
                  </v-btn>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Map from "@/components/Map.vue";
import { mapGetters } from "vuex";

export default {
  name: "clientLocation",
  components: { Map },
  props: ["dialog", "lat", "lng", "formatted_address"],
  data() {
    return {
      branchInfo: {},
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.main-hr {
  border-top: 2px solid #d9d9d9;
  margin: 1rem 0 2rem;
  border-bottom: 0 !important;
}
#subscribe-dialog {
  //   padding: 50px 0 !important;
  background-color: transparent;
  .right-col {
    // height: 630px;
    position: relative;
    padding: 100px 0;
    border-top: 5px solid #a53860;
    border-radius: 0 0 0 60px;
    padding: 1rem 3rem;
    background-color: #fff;
    margin-bottom: 10px !important;
    padding-top: 100px !important;
    padding-bottom: 25px !important;
    .appLinks {
      .app-img {
        max-width: 130px !important;
        margin-bottom: 12px;
        margin-right: 5px;
        margin-left: 5px;
        border: 1px solid #ccc;
        border-radius: 12px;
      }
    }
    h2 {
      font-size: 1.5rem !important;
    }

    .close-action {
      position: absolute;
      bottom: 10%;
      right: 30%;
    }
  }
  .left-col {
    // height: 630px;
    border-bottom: 5px solid #fff;
    border-radius: 0 70px 0 0;
    padding: 1rem 2rem;
    background-color: #2d3533;
    margin-top: 10px;

    .slogan {
      position: absolute;
      top: 40%;
      right: 10%;
      font-size: 1.5rem;
      font-weight: bold;
      .slogan1 {
        // font-size: 2rem;
        margin-bottom: 3rem;
      }
      .slogan2 {
        // font-size: 2rem;
      }
    }
  }
}
</style>
